@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;500;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply box-border;
}
html {
  @apply scroll-smooth;
}
body {
  @apply m-0 p-0 text-white bg-gray-900 font-quicksand text-base font-normal;
}
@layer components {
  a {
    @apply no-underline hover:underline;
  }
  input,
  textarea {
    @apply focus:outline-none bg-transparent;
  }

.web3modal-modal-lightbox { z-index: 2000 !important; }
/* ================= Preloader ================= */
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1050;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
  /* ================= Gradient Layer ================= */
  .gradientLayer,
  .gradientLayerBoth {
    @apply z-0 relative;
  }
  .gradientLayer::after,
  .gradientLayerBoth::after,
  .gradientLayerBoth::before {
    @apply content-[''] absolute top-0 right-0 bottom-0 left-0 z-10 bg-imageOverly;
  }
  .gradientLayerBoth::before {
    @apply rotate-180;
  }

  /* ================= Dropdown ================= */
  .dropdown-button .dropdown-icon {
    filter: contrast(0) brightness(4);
  }
  .dropdown-button:hover .dropdown-icon {
    filter: contrast(1) brightness(1);
  }
}

/* videoWrapper */

.videoWrapper::after,
.videoWrapper::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.videoWrapper::after {
  background: linear-gradient(180deg, rgba(13, 13, 13, 0) 0%, #0d0d0d 100%),
    linear-gradient(180deg, rgba(13, 13, 13, 0) 0%, #0d0d0d 100%);
  opacity: 0.92;
}
.videoWrapper::before {
  background: linear-gradient(180deg, rgba(13, 13, 13, 0) 0%, #0d0d0d 100%),
    linear-gradient(180deg, rgba(13, 13, 13, 0) 0%, #0d0d0d 100%);
  transform: rotate(180deg);
  opacity: 0.92;
}

/* ================= Technology Card ================= */
.technology-card {
  @apply bg-primaryGradient z-0 hover:no-underline shadow-100 rounded-2xl cursor-pointer duration-100 overflow-hidden block;
}
.technology-card::before {
  @apply content-[''] absolute top-0 right-0 bottom-0 left-0 -z-10 hidden;
}
.technology-card:hover::before {
  @apply block;
  animation: card ease-out 400ms forwards;
}
@keyframes card {
  from {
    background-image: linear-gradient(#333333, #333333);
    opacity: 0;
  }
  to {
    background-image: linear-gradient(#333333, #333333);
    opacity: 1;
  }
}

.technology-card-content {
  @apply relative z-20 p-6;
}
.technolgy-card-content {
  @apply content-[''] absolute top-0 right-0 bottom-0 left-0 rotate-180 bg-technologyCardContent;
}

/* ================= Slider ================= */
.horizontal-slider {
  width: 100%;
  height: 4px;
}
.thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  width: 28px !important;
  height: 28px !important;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.thumb.active {
  background-color: #aaa;
}
.track {
  position: relative;
  background: #4d4d4d;
}
.track.track-0 {
  background: #fb9228;
}
.horizontal-slider .track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .thumb {
  top: 50%;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
  transform: translate(0%, calc(50% - 8px));
}

/* ================= Technology slider dots ================= */
.slick-dots {
  display: flex !important;
  justify-content: center !important;
  gap: 8px !important;
  bottom: -32px !important;
}

.slick-dots li {
  width: 0 !important;
  height: 0 !important;
}
.slick-dots li:not(.slick-active) {
  opacity: 0.3 !important;
}
